/* SharedHero.css - Estilos compartidos para todos los componentes Hero */

:root {
    /* Colores */
    --color-primary: #02A724;
    --color-background: #0A2540;
    --color-text: white;
    --color-accent: #4CAF50;

    /* Tipografía */
    --font-size-h1-desktop: 4.209rem;
    --font-size-h1-tablet: 2.8rem;
    --font-size-h1-mobile: 2.2rem;

    --font-size-h2-desktop: 2.369rem;
    --font-size-h2-tablet: 1.6rem;
    --font-size-h2-mobile: 1.2rem;

    --font-size-body-desktop: 1.777rem;
    --font-size-body-tablet: 1.333rem;
    --font-size-body-mobile: 1.1rem;

    --line-height-heading: 1.2;
    --line-height-body: 1.3;

    /* Espaciado */
    --spacing-xs: 0.5rem;
    --spacing-sm: 1rem;
    --spacing-md: 2rem;
    --spacing-lg: 3rem;
    --spacing-xl: 4rem;

    /* Contenedor */
    --container-padding-desktop: 4rem 2rem;
    --container-padding-mobile: 2rem 1rem;
    --container-max-width: 1200px;
    --container-border: 2px solid var(--color-text);

    /* Botones */
    --button-height-desktop: 56px;
    --button-height-mobile: 44px;
    --button-padding-desktop: 0 2.5rem;
    --button-padding-mobile: 0 1.5rem;
    --button-font-size-desktop: 1.333rem;
    --button-font-size-mobile: 1.1rem;
    --button-border-radius: 8px;
}

/* Contenedor principal */
.shared-hero-container {
    width: 100%;
    overflow: hidden;
    background-color: #0A2540;
}

.shared-hero-main-section {
    min-height: 100vh;
    padding: 0;
    background-color: #0A2540;
    position: relative;
}

@media (min-width: 768px) {
    .shared-hero-main-section {
        min-height: 600px;
    }
}

/* Contenedor interno */
.shared-hero-container-inner {
    padding: var(--container-padding-mobile);
    max-width: var(--container-max-width);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 768px) {
    .shared-hero-container-inner {
        padding: var(--container-padding-desktop);
    }
}

/* Contenido */
.shared-hero-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: var(--spacing-lg);
    width: 90%;
    max-width: var(--container-max-width);
    margin: 0 auto;
}

@media (min-width: 768px) {
    .shared-hero-content {
        align-items: flex-start;
        text-align: left;
        padding: var(--spacing-xl);
    }
}

/* Título */
.shared-hero-title {
    color: var(--color-text) !important;
    font-size: var(--font-size-h1-mobile) !important;
    line-height: var(--line-height-heading) !important;
    margin-bottom: var(--spacing-md) !important;
    width: 100%;
    word-wrap: break-word;
}

@media (min-width: 480px) {
    .shared-hero-title {
        font-size: var(--font-size-h1-tablet) !important;
    }
}

@media (min-width: 768px) {
    .shared-hero-title {
        font-size: var(--font-size-h1-desktop) !important;
    }
}

/* Descripción */
.shared-hero-description {
    color: var(--color-text);
    font-size: var(--font-size-h2-mobile);
    line-height: var(--line-height-heading) !important;
    margin-bottom: var(--spacing-md);
    width: 100%;
    word-wrap: break-word;
}

@media (min-width: 480px) {
    .shared-hero-description {
        font-size: var(--font-size-h2-tablet);
    }
}

@media (min-width: 768px) {
    .shared-hero-description {
        font-size: var(--font-size-h2-desktop);
    }
}

/* Subtítulo */
.shared-hero-subtitle {
    color: var(--color-text);
    font-size: var(--font-size-body-mobile);
    line-height: var(--line-height-body);
    margin-bottom: var(--spacing-md);
    font-weight: bold;
}

@media (min-width: 480px) {
    .shared-hero-subtitle {
        font-size: var(--font-size-body-tablet);
    }
}

@media (min-width: 768px) {
    .shared-hero-subtitle {
        font-size: var(--font-size-body-desktop);
    }
}

/* Lista de características */
.shared-hero-feature-list {
    color: var(--color-text);
    font-size: var(--font-size-body-mobile);
    line-height: var(--line-height-body);
    margin-bottom: var(--spacing-md);
    text-align: left;
}

@media (min-width: 480px) {
    .shared-hero-feature-list {
        font-size: var(--font-size-body-tablet);
    }
}

@media (min-width: 768px) {
    .shared-hero-feature-list {
        font-size: var(--font-size-body-desktop);
    }
}

/* Botones */
.shared-hero-buttons {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
    width: 100%;
    align-items: center;
    margin-top: var(--spacing-md);
}

@media (min-width: 768px) {
    .shared-hero-buttons {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: var(--spacing-md);
    }
}

/* Botón primario */
.shared-button-primary {
    background-color: var(--color-primary) !important;
    color: var(--color-text) !important;
    height: var(--button-height-mobile) !important;
    font-size: var(--button-font-size-mobile) !important;
    padding: var(--button-padding-mobile) !important;
    border-radius: var(--button-border-radius) !important;
    transition: all 0.3s ease !important;
    width: auto !important;
    min-width: 200px;
}

.shared-button-primary:hover {
    background-color: var(--color-text) !important;
    color: var(--color-primary) !important;
}

/* Botón secundario */
.shared-button-secondary {
    background-color: var(--color-primary) !important;
    color: var(--color-text) !important;
    height: var(--button-height-mobile) !important;
    font-size: var(--button-font-size-mobile) !important;
    padding: var(--button-padding-mobile) !important;
    border-radius: var(--button-border-radius) !important;
    transition: all 0.3s ease !important;
    width: auto !important;
    min-width: 200px;
}

.shared-button-secondary:hover {
    background-color: var(--color-text) !important;
    color: var(--color-primary) !important;
}

/* Icono del botón */
.shared-button-icon {
    width: 20px;
    height: 20px;
    margin-left: 8px;
    transition: transform 0.3s ease;
}

@media (min-width: 768px) {

    .shared-button-primary,
    .shared-button-secondary {
        height: var(--button-height-desktop) !important;
        font-size: var(--button-font-size-desktop) !important;
        padding: var(--button-padding-desktop) !important;
    }
}

@media (max-width: 480px) {
    .shared-hero-main-section {
        min-height: auto;
        /* Eliminar altura mínima fija */
        max-height: 70vh;
        /* Establecer altura máxima */
    }

    .shared-hero-content {
        padding: var(--spacing-sm);
    }

    /* Reducir el espacio entre elementos */
    .shared-hero-title {
        margin-bottom: var(--spacing-sm) !important;
    }

    .shared-hero-description {
        margin-bottom: var(--spacing-sm);
    }
}