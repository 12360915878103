.custom-button-how,
  .outline-button-how {
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s !important;
    font-weight: bold;
    padding: 0 2.5rem !important;
    height: 56px !important;
    font-size: 1.333rem !important;
    border: 1px solid #02A724 !important;
  }
  
  .custom-button-how {
    background-color: #02A724 !important; /* Usar !important con cautela */
    color: white !important;
    padding: 14px 30px;
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
  }

  .custom-button-how svg {
    width: 20px;
    height: 20px;
    transition: transform 0.3s ease;
  }
  
  .custom-button-how:hover {
    background-color: white !important;
    color: #02A724 !important;
  }
  
  .outline-button-how {
    background-color: #02A724 !important;

    color: white !important;
  }
  
  .outline-button-how:hover {
    background-color: white !important;
    color: #02A724 !important;
  }

  .custom-button-how, .outline-button-how {
    cursor: pointer !important; /* !important para asegurarse, con precaución */
  }


.title-howit {
  margin-bottom: 4rem !important;
  font-size: 3.157rem !important;
  text-align: center;
}


.title-card-howit {
  margin-bottom: 2rem;
  font-size: 1.777rem !important;
}

.description-card-howit {
  margin-bottom: 2rem;
  font-size: 1.333rem;
}



@media (max-width: 768px) {
  .title-howit {
    font-size: 2rem !important;
    margin-bottom: 2rem !important;
    padding: 0 1rem;
  }

  .title-card-howit {
    font-size: 1.2rem !important;
    margin-bottom: 1rem;
  }

  .description-card-howit {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .mobile-steps-carousel {
    width: 100%;
    overflow: hidden;
    padding: 0 20px;
    margin-bottom: 2rem;
  }

  .steps-container {
    width: 100%;
    overflow: hidden;
    margin-bottom: 1rem;
  }

  .steps-slider {
    display: flex;
    transition: transform 0.5s ease;
    width: 100%;
  }

  .steps-slider > * {
    min-width: 100%;
    padding: 0;
  }


  .steps-slider Box {
    width: 90% !important;
    margin: 0 auto !important;
    max-width: 400px !important;
  }

  .step-indicators {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 1rem;
  }

  .step-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ccc;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .step-dot.active {
    background-color: #02A724;
  }

  /* Ajustes para los botones en móvil */
  .custom-button-how,
  .outline-button-how {
    width: auto !important; /* Cambiar de 100% a auto */
    min-width: 150px; /* Establecer un ancho mínimo */
    max-width: 280px; /* Establecer un ancho máximo */
    margin: 0.5rem;
    font-size: 0.9rem !important;
    padding: 0 1rem !important;
    height: 48px !important; /* Reducir altura en móvil */
    white-space: nowrap; /* Evitar que el texto se rompa */
    overflow: hidden;
    text-overflow: ellipsis;
  }
}